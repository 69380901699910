.mainBackground {
  background-image: url('http://splinge.com/images/solo_performer.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full height */
}

.pageBackground {
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  
} 

.pageBackground2 {
  background-image: linear-gradient(to bottom right, black , lightgray);
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh; /* Full height */
  width: 100vw; /* Full width */
} 

.header {
  background-color: white;
}

.logo {
  width: 100px; /* Adjust logo size */
}

.text-end {
  text-align: right;
}

.text-middle {
  align-content: center;
}

.headerButton {
  margin: 0px;
  border: 0px;
} 

.textBox {
  color: black;
  font-size: 14px;
  text-align: center;
  width: 450px; /* Full width */
} 

.contactText {
  color: white;
  font-size: 14px;
  text-align: left;
  width: 100%; /* Full width */
}